<template>
  <div :class="$style.page">
    <el-table :data="pages" stripe>
      <el-table-column prop="title" label="Название" sortable>
        <template slot-scope="scope">
          {{ scope?.row?.content?.title }}
        </template>
      </el-table-column>
      <el-table-column prop="slug" label="Имя в url" />
      <el-table-column width="130">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.UPDATE,
                params: { slug: getSlug(scope?.row?.slug) },
              })
            "
            :view-link="$configData.addevents_link + scope?.row?.slug"
            :add-trash="false"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPage > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getContentPages"
    />
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

import { MODAL_TYPES, SORTING_TYPES } from '@/constants/common'
import {
  ADDEVENT_ADMIN_ROUTES,
  getRoute,
  ADDEVENT_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

export default {
  components: { ActionButtons },
  MODAL_TYPES,
  SORTING_TYPES,
  ADDEVENT_ADMIN_ROUTES,
  ADDEVENT_ROUTES,
  PROJECTS,
  mixins: [notifications],
  data() {
    return {
      pages: [],
      query: {
        page: this.$route.query.page ?? '1',
        limit: '20',
        orderBy: this.$route.query.orderBy ?? 'name',
        orderDir: this.$route.query.orderDir ?? SORTING_TYPES.ASC,
      },
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  async created() {
    await this.getContentPages()
    if (JSON.stringify(this.$route.query) === JSON.stringify(this.query)) return
    this.$router.push({ query: this.query })
  },
  async updated() {
    if (JSON.stringify(this.$route.query) !== JSON.stringify(this.query))
      this.$router.push({ query: this.query })
  },
  methods: {
    getSlug(slug) {
      return slug == '' ? 'main' : slug
    },
    async getContentPages() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.getList('addevent')
      if (error) return
      this.pages = value.data
      this.total = value.meta.count

      loading.close()

      if (error) return

      this.articleCategories = value?.data
      this.count = value?.meta?.count
    },

    getRoute({ route, params, site }) {
      return getRoute({ route, params, site })
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .pagination {
    @include pagination;
  }
}
</style>
